/* ===================================
			     components
====================================== */
@import "components/accordion";
@import "components/feature-box";
@import "components/pricing";
@import "components/testimonials";
@import "components/process";
@import "components/blockquote";
@import "components/swiper-slider";
@import "components/owl-carousel";
@import "components/divider";
@import "components/social-icons";
@import "components/team";
@import "components/skill";
@import "components/counter";
@import "components/animated-headlines";