// Should you need to add your custom variable or you need to override our theme variables from _variables.SCSS, then we highly recommend that you add the code into this file so that whenever theme update is available and you update the theme, You will not lose your hard work :)

// Below given example is for how to overrides core variables. Uncomment the code, after compilation, You will see the color scheme changes on frontend.

// User Variables

$primary:     #0c2f63;
$light: #0c2f6317 ;



$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

//Theme gradient Colors - Starting and ending point
$grad-start:      $primary;
$grad-end:        #7facef;

$link-color: $primary;
$link-hover-color:          darken($link-color, 10%);

$navbar-light-hover-color:          $primary;
$navbar-light-active-color:         $primary;

$dropdown-link-hover-color:         $primary !important;
$dropdown-link-active-color:        $primary;

$font-family-base:            'Nunito Sans', sans-serif;
$font-family-heading:         'Nunito Sans', sans-serif;
$font-family-alt:             'Nunito Sans', sans-serif;

$headings-font-family:          $font-family-heading;

