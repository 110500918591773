// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

/*User CSS*/

.bg-overlay-dark-7:before {
    background: #04307194;
}

.feature-box-image  {
    margin: 1em;
    margin-left: 0;
}

.service-block img  {
    max-height: 350px;
}

.distributor-logo {
    display: block;
    max-width: 200px;
    margin: 30px 0;
    cursor: pointer;
}

.bg-light-maidmist {
    background-color: #e9ecf1;
}

.banner-bg {
    background: #001e4da8;
    padding-top: 1em;
}

body {
    background-image: url("/assets/images/9947.jpg");
    background-size: contain;
    background-attachment: fixed;
}

$background: #ffffffe8;

header {
    background: $background;
}

section {
    background: $background;
}
